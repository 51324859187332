import { keyBy } from "lodash";

import { useApiRequest } from "../../../../../hooks/accessApi";
import { TourSessions } from "../../../../../store/insights/InsightsPatrolTasksModel";
import { parseDateRangeRequestBody } from "../../../../../store/parseDateRangeRequestBody";

export const useTourSessionsPerSite = (siteIds: string[], startDate: Date, endDate: Date) => {
    const { data: tourData, isLoading } = useApiRequest<TourSessions[]>(
        `/v2/global/insights/tour-sessions/filter`,
        "POST",
        parseDateRangeRequestBody({
            siteIds,
            fromDateTime: startDate,
            toDateTime: endDate,
        }),
    );

    const tourSessionsMap = keyBy(tourData, "locationId");

    return { tourSessionsMap, isLoading };
};
