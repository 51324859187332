import { Box, Breakpoints, LoadingIndicator, Stack, Tab, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { InsightsDateFilter } from "src/components/Insights/Filter/InsightsDateFilter";
import { ScheduleDateFilter } from "src/components/Schedule/ScheduleDateFilter";
import { PageWrapper } from "src/components/shared/PageWrapper";
import { contextKinds, featureFlags } from "src/data/featureFlags";
import { useFeatureFlag, useSetFeatureFlagContextAsync } from "src/hooks/featureFlags";
import { ScheduleTableSection } from "src/pages/Schedule/ScheduleTableSection";

import ClientSwitcherSelectors from "../../../store/clientSwitcher/ClientSwitcherSelectors";
import { useTours } from "../../../store/insights/Tours/ToursHooks";
import LocationsSelectors from "../../../store/locations/LocationsSelectors";
import UserSelectors from "../../../store/user/UserSelectors";
import { InsightsWrapper } from "../Common.styled";
import useSelectDateRangeFromLocation from "../shared/hooks/useSelectDateRangeFromLocation";
import { SitePageTabLabel } from "../shared/types";
import { IncidentsTab } from "./components/tabs/IncidentsTab";
import { TasksTab } from "./components/tabs/TasksTab";
import { StackStyled, TabsStyled } from "./InsightsSitePage.styled";
import { SelectedCategories } from "./InsightsSitePage.types";
import { InsightsSitePageHeader } from "./InsightsSitePageHeader";

export const InsightsSitePage = () => {
    const [activeTab, setActiveTab] = useState<number | null>(null);
    const [selectedCategories, setSelectedCategories] = useState<SelectedCategories>({ level1: null, level2: null });
    const { t } = useTranslation();
    const { siteId } = useParams<{ siteId?: string }>();
    const { state } = useLocation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const locationDetails = useSelector(LocationsSelectors.getAuthorizedLocationById(siteId));
    const userId = useSelector(UserSelectors.getUserId);
    const selectedClient = useSelector(ClientSwitcherSelectors.getSelectedClient);
    const isViewScheduleVisible = useFeatureFlag(featureFlags.viewSchedule);
    const isLevel1OnMobile = selectedCategories.level1 && isMobile;

    useTours();
    useSelectDateRangeFromLocation();

    const context = useMemo(() => {
        return {
            kind: contextKinds.multi,
            user: {
                key: userId,
            },
            client: {
                key: selectedClient.id,
            },
            [contextKinds.locationCountryCode]: {
                key: locationDetails.countryCode || "",
            },
        };
    }, [userId, selectedClient, locationDetails]);

    const { isLoading } = useSetFeatureFlagContextAsync(context);

    const levelChangeHandler = (selectedCategories: SelectedCategories) => {
        setSelectedCategories(selectedCategories);
    };

    const levelResetHandler = (index: number) => {
        if (index === eventsTabIndex && activeTab === eventsTabIndex && selectedCategories.level1) {
            setSelectedCategories({ level1: null, level2: null });
        }
    };

    const TabsList: Array<{
        labelKey: string;
        component: React.ComponentType<any>;
        props?: any;
    }> = [
        isViewScheduleVisible && {
            labelKey: SitePageTabLabel.ViewSchedule,
            component: ScheduleTableSection,
            props: {
                locationId: siteId,
            },
        },
        {
            labelKey: SitePageTabLabel.Events,
            component: IncidentsTab,
            props: {
                selectedCategories: selectedCategories,
                levelChangeHandler: levelChangeHandler,
            },
        },
        {
            labelKey: SitePageTabLabel.Tasks,
            component: TasksTab,
        },
    ].filter(Boolean);

    useEffect(() => {
        if (isLoading === false) {
            setActiveTab(state?.originActiveTab ? TabsList.findIndex((tab) => tab.labelKey === state.originActiveTab) : 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [TabsList?.length, state?.originActiveTab, isLoading]);

    const eventsTabIndex = TabsList.findIndex((tab) => tab.labelKey === SitePageTabLabel.Events);
    const viewScheduleTabIndex = TabsList.findIndex((tab) => tab.labelKey === SitePageTabLabel.ViewSchedule);
    const displayScheduleDatePicker = isViewScheduleVisible && activeTab === viewScheduleTabIndex;

    return (
        <PageWrapper direction="column" pt={isMobile ? "XXS" : "0"}>
            <InsightsWrapper pb="S" direction="column">
                <Box ph={isMobile ? "S" : "L"}>
                    <InsightsSitePageHeader
                        selectedCategories={selectedCategories}
                        activeTab={activeTab}
                        levelChangeHandler={levelChangeHandler}
                        siteId={siteId}
                        InsightsDateFilter={displayScheduleDatePicker ? ScheduleDateFilter : InsightsDateFilter}
                        eventsTabIndex={eventsTabIndex}
                    />
                </Box>
                {isLoading ? (
                    <Stack justifyContent="center" alignItems="center" flex="1">
                        <LoadingIndicator size="L" />
                    </Stack>
                ) : (
                    <Stack gap="S" direction="column">
                        <TabsStyled
                            data-testid="insights-tabs"
                            useScrollIntoView={false}
                            value={activeTab}
                            onChange={setActiveTab}
                            isNavigationVisible={!isLevel1OnMobile}
                            hasBottomSeparator={!isLevel1OnMobile}
                            onClick={levelResetHandler}
                            scrollBehavior="instant"
                        >
                            {TabsList.map((tab, index) => {
                                const Page = tab.component;

                                return (
                                    <Tab key={tab.labelKey} label={t(tab.labelKey)}>
                                        <StackStyled direction="column" flex={1} gap="S">
                                            {activeTab === index && <Page {...tab.props} />}
                                        </StackStyled>
                                    </Tab>
                                );
                            })}
                        </TabsStyled>
                    </Stack>
                )}
            </InsightsWrapper>
        </PageWrapper>
    );
};
