import { IsoStringDate } from "../../helpers/date";
import { ActivityLogFields, ActivityLogFieldsOld } from "../../models/reportTemplates/ActivityLog";
import { AlarmResponseFields } from "../../models/reportTemplates/AlarmResponse";
import { CalloutFields } from "../../models/reportTemplates/Callout";
import { EventFields, EventFieldsOld } from "../../models/reportTemplates/Incident";
import { MaintenanceFields, MaintenanceFieldsOld } from "../../models/reportTemplates/Maintenance";
import { SafetyInspectionFields, SafetyInspectionFieldsOld } from "../../models/reportTemplates/SafetyInspection";
import { SiteInspectionFields } from "../../models/reportTemplates/SiteInspection";
import { TaskReportFields, TaskReportFieldsOld } from "../../models/reportTemplates/Task";
import { AmazonGeneralFields } from "./AmazonGeneral";
import { AmazonZone6Fields } from "./AmazonZone6";
import { AssignmentFields } from "./Assignment";
import { TourExceptionFields } from "./TourException";
import { TourMultiExceptionFields } from "./TourMultiException";
import { VehicleInspectionFields, VehicleInspectionFieldsOld } from "./VehicleInspection";

export {
    ActivityLogFields,
    ActivityLogFieldsOld,
    AlarmResponseFields,
    AmazonGeneralFields,
    AmazonZone6Fields,
    CalloutFields,
    EventFields,
    EventFieldsOld,
    AssignmentFields,
    MaintenanceFields,
    MaintenanceFieldsOld,
    SafetyInspectionFields,
    SafetyInspectionFieldsOld,
    SiteInspectionFields,
    TaskReportFields,
    TaskReportFieldsOld,
    TourMultiExceptionFields,
    TourExceptionFields,
    VehicleInspectionFields,
    VehicleInspectionFieldsOld,
};

export enum ExceptionType {
    Signature = "exception_signature",
    Image = "exception_picture",
    HighQualityImage = "exception_high_quality_picture",
    Yes = "exception_yes",
    No = "exception_no",
    TextField = "...",
    Range = "....",
}

export type ExceptionQuestionData = {
    answer?: string;
    question: string;
    questionIndex?: number;
    isException?: boolean;
    scannedOn?: IsoStringDate;
    exceptionType?: ExceptionType;
};
