import { createReducer, current } from "@reduxjs/toolkit";
import { subDays } from "src/helpers/date";

import { Databases } from "../../models/DbModel";
import { RequestStatus } from "../RequestStatus";
import {
    ICriticalCardsState,
    IDamageReportsState,
    IDbState,
    IFilterState,
    IIncidentMatrixState,
    IIncidentsLocationsState,
    IInsightsState,
    ILocationToursState,
    INCIDENTS_LOCATIONS_REQUEST,
    INCIDENTS_LOCATIONS_SUCCESS,
    INSIGHTS_CRITICAL_CARDS_NEXT_PAGE_SUCCESS,
    INSIGHTS_CRITICAL_CARDS_REQUEST,
    INSIGHTS_CRITICAL_CARDS_REQUEST_NEXT_PAGE,
    INSIGHTS_CRITICAL_CARDS_RESET,
    INSIGHTS_CRITICAL_CARDS_SUCCESS,
    INSIGHTS_DAMAGE_REPORTS_REQUEST,
    INSIGHTS_DAMAGE_REPORTS_SUCCESS,
    INSIGHTS_DB_FAILURE,
    INSIGHTS_DB_REQUEST,
    INSIGHTS_DB_SUCCESS,
    INSIGHTS_DBS_RESET,
    INSIGHTS_FILTER_APPLY_PERSISTED_FILTERS,
    INSIGHTS_FILTER_SELECT_DATE_RANGE,
    INSIGHTS_FILTER_SELECT_GROUPS_FILTER,
    INSIGHTS_FILTER_SELECT_LOCATIONS_FILTER,
    INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_FAILURE,
    INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_REQUEST,
    INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_SUCCESS,
    INSIGHTS_LOCATION_TOURS_FAILURE,
    INSIGHTS_LOCATION_TOURS_REQUEST,
    INSIGHTS_LOCATION_TOURS_SUCCESS,
    INSIGHTS_MARK_CLIENT_VERIFIED,
    INSIGHTS_PATROL_DEVIATIONS_LEVEL2_SUCCESS,
    INSIGHTS_PATROL_DEVIATIONS_REQUEST,
    INSIGHTS_PATROL_DEVIATIONS_SUCCESS,
    INSIGHTS_SET_INCIDENT_MATRIX_CATEGORY,
    INSIGHTS_SET_INCIDENT_MATRIX_IS_EXPANDED,
    INSIGHTS_SET_INCIDENT_MATRIX_LEVEL,
    INSIGHTS_SET_SELECTED_TAB,
    INSIGHTS_TOUR_SESSIONS_SUMMARY_FAILURE,
    INSIGHTS_TOUR_SESSIONS_SUMMARY_REQUEST,
    INSIGHTS_TOUR_SESSIONS_SUMMARY_SUCCESS,
    IPatrolDeviationsState,
    IPerformedPatrolsState,
    ISummaryState,
    ITourSessionsSummaryState,
} from "./types";

const DEFAULT_FILTER_START_DATE_OFFSET_IN_DAYS = 29;

export const defaultFilterState: IFilterState = {
    selectedRegions: [],
    selectedGroups: [],
    selectedStartDate: subDays(new Date(new Date().setHours(0, 0, 0, 0)), DEFAULT_FILTER_START_DATE_OFFSET_IN_DAYS),
    selectedEndDate: new Date(new Date().setHours(23, 59, 59, 999)),
};

const defaultDamageReportsState: IDamageReportsState = {
    queryStatus: RequestStatus.undefined,
    error: null,
    damageReports: {},
    key: null,
};

const defaultIncidentLocationsState: IIncidentsLocationsState = {
    queryStatus: RequestStatus.undefined,
    error: null,
    incidentsLocations: [],
};

const defaultDbState: IDbState = {
    loadStatus: RequestStatus.undefined,
    error: null,
};

const defaultPerformedPatrolsState: IPerformedPatrolsState = {
    queryStatus: RequestStatus.undefined,
};

const defaultCriticalCardsState: ICriticalCardsState = {
    cards: [],
    queryStatus: RequestStatus.undefined,
    page: 0,
    queryKey: null,
};

const defaultTourSessionsSummaryState: ITourSessionsSummaryState = {
    queryStatus: RequestStatus.undefined,
    queryKey: null,
    tourSessionsSummary: [],
};

const defaultPatrolDeviationsState: IPatrolDeviationsState = {
    patrolDeviations: [],
    queryStatus: RequestStatus.undefined,
    queryKey: null,
    error: null,
};

const defaultLocationToursState: ILocationToursState = {
    tours: [],
    queryStatus: RequestStatus.undefined,
    queryKey: null,
    error: null,
};

const defaultSummaryState: ISummaryState = {
    isClientVerified: false,
    hasData: false,
};

const defaultIncidentMatrixState: IIncidentMatrixState = {
    category: null,
    level: 1,
    isExpanded: false,
};

export const defaultState: IInsightsState = {
    filterState: defaultFilterState,
    damageReportsState: defaultDamageReportsState,
    incidentsLocationsState: defaultIncidentLocationsState,
    performedPatrolsState: defaultPerformedPatrolsState,
    tourSessionsSummary: defaultTourSessionsSummaryState,
    historicalTourSessionsSummary: defaultTourSessionsSummaryState,
    databasesState: {
        [Databases.Reports]: defaultDbState,
    },
    criticalCardsState: defaultCriticalCardsState,
    patrolDeviationsState: defaultPatrolDeviationsState,
    selectedTab: 0,
    locationToursState: defaultLocationToursState,
    summaryState: defaultSummaryState,
    incidentMatrixState: defaultIncidentMatrixState,
};

const InsightsReducer = createReducer(defaultState, {
    [INSIGHTS_DB_REQUEST]: (state, action) => {
        state.databasesState[action.meta.dbId].loadStatus = RequestStatus.loading;
        state.databasesState[action.meta.dbId].error = null;
    },
    [INSIGHTS_DB_SUCCESS]: (state, action) => {
        state.databasesState[action.meta.dbId].loadStatus = RequestStatus.success;
        state.databasesState[action.meta.dbId].error = null;
    },
    [INSIGHTS_DB_FAILURE]: (state, action) => {
        state.databasesState[action.meta.dbId].loadStatus = RequestStatus.error;
        state.databasesState[action.meta.dbId].error = action.payload;
    },
    [INSIGHTS_DBS_RESET]: (state) => {
        state.databasesState = defaultState.databasesState;
    },
    [INSIGHTS_FILTER_SELECT_LOCATIONS_FILTER]: (state, action) => {
        state.filterState.selectedRegions = action.payload.selectedRegions;
    },
    [INSIGHTS_FILTER_SELECT_GROUPS_FILTER]: (state, action) => {
        state.filterState.selectedGroups = action.payload.selectedGroups;
    },
    [INSIGHTS_FILTER_SELECT_DATE_RANGE]: (state, action) => {
        state.filterState.selectedStartDate = action.payload.selectedStartDate;
        state.filterState.selectedEndDate = action.payload.selectedEndDate;
    },
    [INSIGHTS_FILTER_APPLY_PERSISTED_FILTERS]: (state, action) => {
        state.filterState.selectedRegions = action.payload.selectedRegions || [];
        state.filterState.selectedGroups = action.payload.selectedGroups || [];
        if (action.payload.selectedStartDate) {
            state.filterState.selectedStartDate = new Date(action.payload.selectedStartDate);
        }
        if (action.payload.selectedEndDate) {
            state.filterState.selectedEndDate = new Date(action.payload.selectedEndDate);
        }
    },
    [INSIGHTS_DAMAGE_REPORTS_REQUEST]: (state, action) => {
        state.damageReportsState.queryStatus = RequestStatus.loading;
        state.damageReportsState.error = null;
        state.damageReportsState.damageReports = {};
        state.damageReportsState.key = action.payload;
    },
    [INSIGHTS_DAMAGE_REPORTS_SUCCESS]: (state, action) => {
        if (action.payload.key !== state.damageReportsState?.key) {
            return;
        }
        state.damageReportsState.queryStatus = RequestStatus.success;
        state.damageReportsState.damageReports = action.payload.damageReports;
        state.damageReportsState.key = action.payload.key;
    },
    [INCIDENTS_LOCATIONS_REQUEST]: (state) => {
        state.incidentsLocationsState.queryStatus = RequestStatus.loading;
        state.incidentsLocationsState.error = null;
        state.incidentsLocationsState.incidentsLocations = [];
    },
    [INCIDENTS_LOCATIONS_SUCCESS]: (state, action) => {
        state.incidentsLocationsState.queryStatus = RequestStatus.success;
        state.incidentsLocationsState.incidentsLocations = action.payload;
    },
    [INSIGHTS_CRITICAL_CARDS_REQUEST]: (state) => {
        state.criticalCardsState.queryStatus = RequestStatus.loading;
    },
    [INSIGHTS_CRITICAL_CARDS_SUCCESS]: (state, action) => {
        state.criticalCardsState.queryStatus = RequestStatus.success;
        state.criticalCardsState.cards = action.payload.cards;
        state.criticalCardsState.page = 0;
        state.criticalCardsState.queryKey = action.payload.queryKey;
    },
    [INSIGHTS_CRITICAL_CARDS_REQUEST_NEXT_PAGE]: (state) => {
        state.criticalCardsState.page = state.criticalCardsState.page + 1;
    },
    [INSIGHTS_CRITICAL_CARDS_NEXT_PAGE_SUCCESS]: (state, action) => {
        state.criticalCardsState.queryStatus = RequestStatus.success;
        state.criticalCardsState.cards = [...state.criticalCardsState.cards, ...action.payload];
    },
    [INSIGHTS_CRITICAL_CARDS_RESET]: (state) => {
        state.criticalCardsState = defaultCriticalCardsState;
    },
    [INSIGHTS_TOUR_SESSIONS_SUMMARY_SUCCESS]: (state, action) => {
        state.tourSessionsSummary.queryStatus = RequestStatus.success;
        state.tourSessionsSummary.tourSessionsSummary = action.payload;
        state.tourSessionsSummary.queryKey = action.meta.queryKey;
    },
    [INSIGHTS_TOUR_SESSIONS_SUMMARY_FAILURE]: (state, action) => {
        state.tourSessionsSummary.queryStatus = RequestStatus.error;
        state.tourSessionsSummary.tourSessionsSummary = action.payload;
    },
    [INSIGHTS_TOUR_SESSIONS_SUMMARY_REQUEST]: (state) => {
        state.tourSessionsSummary.queryStatus = RequestStatus.loading;
        state.tourSessionsSummary.tourSessionsSummary = [];
    },
    [INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_SUCCESS]: (state, action) => {
        state.historicalTourSessionsSummary.queryStatus = RequestStatus.success;
        state.historicalTourSessionsSummary.tourSessionsSummary = action.payload;
    },
    [INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_FAILURE]: (state, action) => {
        state.historicalTourSessionsSummary.queryStatus = RequestStatus.error;
        state.historicalTourSessionsSummary.tourSessionsSummary = action.payload;
    },
    [INSIGHTS_HISTORICAL_TOUR_SESSIONS_SUMMARY_REQUEST]: (state) => {
        state.historicalTourSessionsSummary.queryStatus = RequestStatus.loading;
        state.historicalTourSessionsSummary.tourSessionsSummary = [];
    },
    [INSIGHTS_PATROL_DEVIATIONS_REQUEST]: (state) => {
        state.patrolDeviationsState.queryStatus = RequestStatus.loading;
    },
    [INSIGHTS_PATROL_DEVIATIONS_SUCCESS]: (state, action) => {
        state.patrolDeviationsState.queryStatus = RequestStatus.success;
        state.patrolDeviationsState.queryKey = action.queryKey;
        state.patrolDeviationsState.patrolDeviations = action.payload;
    },
    [INSIGHTS_PATROL_DEVIATIONS_LEVEL2_SUCCESS]: (state, action) => {
        const currentState = current(state);
        const { payload, categoryLevel1 } = action;
        state.patrolDeviationsState.patrolDeviations = currentState.patrolDeviationsState.patrolDeviations.map((p) => {
            if (p.category === categoryLevel1) {
                return {
                    ...p,
                    children: payload,
                };
            }
            return p;
        });
    },
    [INSIGHTS_SET_SELECTED_TAB]: (state, action) => {
        state.selectedTab = action.payload;
    },
    [INSIGHTS_MARK_CLIENT_VERIFIED]: (state, action) => {
        state.summaryState.isClientVerified = true;
        state.summaryState.hasData = action.payload;
    },
    [INSIGHTS_LOCATION_TOURS_REQUEST]: (state) => {
        state.locationToursState.queryStatus = RequestStatus.loading;
    },
    [INSIGHTS_LOCATION_TOURS_SUCCESS]: (state, action) => {
        state.locationToursState.queryStatus = RequestStatus.success;
        state.locationToursState.queryKey = action.queryKey;
        state.locationToursState.tours = action.payload;
    },
    [INSIGHTS_LOCATION_TOURS_FAILURE]: (state, action) => {
        state.patrolDeviationsState.queryStatus = RequestStatus.error;
        state.patrolDeviationsState.error = action.payload;
    },
    [INSIGHTS_SET_INCIDENT_MATRIX_CATEGORY]: (state, action) => {
        state.incidentMatrixState.category = action.payload;
    },
    [INSIGHTS_SET_INCIDENT_MATRIX_LEVEL]: (state, action) => {
        state.incidentMatrixState.level = action.payload;
    },
    [INSIGHTS_SET_INCIDENT_MATRIX_IS_EXPANDED]: (state, action) => {
        state.incidentMatrixState.isExpanded = action.payload;
    },
});

export default InsightsReducer;
